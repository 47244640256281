import request from '@/utils/request'

export function fetchSummaryDates() {
	return request({
		url: '/v1/summary/dates',
		method: 'get',
	})
}

export function fetchSummary(query) {
	return request({
		url: '/v1/summary',
		method: 'get',
		params: query
	})
}
