<template>
  <div class="analysis-weekly-board">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="toolbar">
      <el-form @submit.native.prevent :inline="true" size="small" class="search-form">
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 128px" v-model="currentDate" filterable>
            <el-option v-for="item in dateList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="download-btn" :class="{ hidden: !allowDownload }">
        <el-button size="small" class="excel-dl-btn" icon="el-icon-download" @click="displayDownload">{{
          $t("common.download") }}</el-button>
      </div>
    </div>
    <el-row class="board-container" :gutter="10">
      <el-col :lg="24" :xl="12" v-for="d in tableData" :key="d.id">
        <div class="board-area">
          <div class="board-header">
            <div class="board-title">{{ d.title }}</div>
            <div class="board-subtitle">{{ d.subtitle }}</div>
          </div>
          <el-row :gutter="10" class="board-body">
            <el-col :lg="{ span: spanClass(d.items ? d.items.length : 0) }" v-for="(t, i) of d.items" :key="t.title">
              <div class="content-header">{{ t.category }}</div>
              <div class="content-body">
                <el-row class="content-key">
                  <el-col :span="4">{{ typeTitle(d.type).title1 }}</el-col>
                  <el-col :span="12">{{ typeTitle(d.type).title2 }}</el-col>
                  <el-col :span="8">{{ typeTitle(d.type).title3 }}</el-col>
                </el-row>
                <el-row class="content-value" v-for="c in t.contents" :key="c.id">
                  <el-col class="content-value1" :span="4">{{
                    rankText(c.id + 1)
                  }}</el-col>
                  <el-col class="content-value2" :span="12">
                    <div :class="{
                      line2: d.type === 'item' || d.type === 'brand',
                      line3: d.type === 'product',
                    }">
                      {{ c.value1 }}
                    </div>
                    <div v-if="d.type === 'product'" class="smallsize">
                      {{ c.value2 }}元 {{ c.value3 }}万件
                    </div>
                    <div v-else-if="d.type === 'color'" class="smallsize">
                      {{ c.value2 + "%" }}
                    </div>
                  </el-col>
                  <el-col class="content-value3" :span="8">
                    <div v-if="d.type === 'brand'">
                      <el-image class="brand-img" :src="c.additional | imageUrl" fit="contain" />
                    </div>
                    <div v-else-if="d.type === 'product'">
                      <el-image class="product-img" :src="c.additional" :preview-src-list="[c.additional]"
                        fit="contain" />
                    </div>
                    <div v-else-if="d.type === 'item'" class="progress-bar">
                      <div class="progress-bar-outer">
                        <div class="progress-bar-inner" :style="{
                          width:
                            displayPercentage(t.contents, c.additional) + '%',
                          'background-color': progressColors[i],
                        }">
                          <div class="progress-bar-text">
                            {{ c.additional + "%" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="d.type === 'color'">
                      <el-image class="color-img" :src="require('@/assets/colors/' + c.additional + '.png')
                        " :alt="c.additional" fit="contain" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="dialogVisible" width="40%" :title="$t('common.pleaseSelect')" @close="handleDialogClose">
      <el-checkbox-group class="download-select" v-model="downloadList">
        <el-checkbox :label="item.id" :key="item.id" v-for="item in tableData">
          {{ item.title }}
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="!downloadList || downloadList.length === 0" class="excel-dl-btn" size="small"
          @click="handleDownload">{{ $t("common.download") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AiBreadcrumb from "@/components/AiBreadcrumb";
import { fetchSummaryDates, fetchSummary } from "@/api/summary";
import { downloadFileAuth } from "@/utils/download-file";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import Guide from "@/components/Guide";

export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      currentDate: null,
      dialogVisible: false,
      downloadList: [],
      dateList: [],
      tableData: [],
      typeList: [
        {
          key: "brand",
          name: "Brand",
          title1: "No.",
          title2: this.$t("common.brand"),
          title3: "LOGO",
        },
        {
          key: "item",
          name: "Item",
          title1: "No.",
          title2: this.$t("common.item"),
          title3: this.$t("common.proportion"),
        },
        {
          key: "product",
          name: "Product",
          title1: "No.",
          title2: this.$t("common.product"),
          title3: this.$t("common.image"),
        },
        {
          key: "color",
          name: "Color",
          title1: "No.",
          title2: this.$t("common.proportion"),
          title3: this.$t("common.color"),
        },
      ],
      progressColors: ["#f56c6c", "#409eff", "#e6a23c", "#67c23a", "#9933ff"],
      sortBase: ["Woman", "女装", "Man", "男装", "Child", "童装", "UniSex", "Sports", "运动"],
    };
  },
  created() {
    this.fetchDateList();
  },
  methods: {
    fetchData() {
      if (!this.currentDate) {
        return;
      }
      this.downloadList = [];
      fetchSummary({ date: this.currentDate }).then((response) => {
        if (response.success) {
          let results = response.result.items || [];
          results.forEach((t) => {
            let sortedItems = [];
            this.sortBase.forEach((e) => {
              t.items &&
                t.items.forEach((tt) => {
                  if (tt.category === e) {
                    tt.contents &&
                      tt.contents.forEach((c, i) => {
                        c.id = i;
                      });
                    sortedItems.push(tt);
                  }
                });
            });
            t.items = sortedItems;
            this.downloadList.push(t.id);
          });
          this.tableData = results;
        }
      });
    },
    fetchDateList() {
      fetchSummaryDates().then((response) => {
        if (response.success) {
          this.dateList = response.result.items || [];
          if (this.dateList && this.dateList.length > 0) {
            this.currentDate = this.dateList[0];
          }
        }
      });
    },
    displayPercentage(contents, value) {
      let h = 0;
      contents.forEach((e) => {
        let v3 = parseFloat(e.additional);
        if (v3 > h) {
          h = v3;
        }
      });
      if (h > 0) {
        return Math.round((parseFloat(value) / h) * 100);
      }
      return 0;
    },
    typeTitle(key) {
      for (let i = 0; i < this.typeList.length; i++) {
        const e = this.typeList[i];
        if (key === e.key) {
          return e;
        }
      }
    },
    rankText(rank) {
      if (rank === 1) {
        return "1st";
      } else if (rank === 2) {
        return "2nd";
      } else if (rank === 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    spanClass(len) {
      if (len === 4) {
        return "4-24";
      } else if (len === 3) {
        return "8";
      } else if (len === 2) {
        return "12";
      } else if (len === 1) {
        return "24";
      } else {
        return "5-24";
      }
    },
    handleDownload() {
      if (!this.downloadList || this.downloadList.length === 0) {
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      let loadingLength = this.downloadList.length;
      this.downloadList.forEach((e) => {
        downloadFileAuth(
          aiApiRoot + "/v1/summary/export/" + e,
          this.currentDate + "_weekly_board_" + e + ".xlsx",
          getToken(),
          () => {
            if (loadingLength === 1) {
              loading.close();
              this.dialogVisible = false;
            } else {
              loadingLength--;
            }
          }
        );
      });
    },
    displayDownload() {
      this.dialogVisible = true;
    },
    handleDialogClose() {
      this.dialogVisible = false;
    },
  },
  watch: {
    currentDate() {
      this.fetchData();
    },
  },
  computed: {
    allowDownload() {
      let allow = false;
      this.$store.getters.my &&
        this.$store.getters.my.serviceCodes &&
        this.$store.getters.my.serviceCodes.forEach((e) => {
          if (e === "data") {
            allow = true;
          }
        });
      return allow;
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-weekly-board {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .el-col-lg-4-24 {
    width: 1/4 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .toolbar {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    text-align: right;

    .el-form-item {
      margin-bottom: 0;
    }

    .search-form {
      float: left;
      width: auto;

      .search-form-gutter {
        margin-right: 30px;
      }
    }

    .download-btn {
      display: inline-block;
    }

    .download-btn.hidden {
      visibility: hidden;
    }
  }

  .board-area {
    background-color: #fff;

    .board-header {
      padding: 10px;
      color: #fff;
      background-color: #909399;
      overflow: hidden;

      .board-title {
        float: left;
        font-weight: 500;
      }

      .board-subtitle {
        text-align: right;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .board-body {
      padding: 10px;

      .content-header {
        height: 24px;
        line-height: 24px;
        color: #606266;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .content-body {
        border: #bbbdbf dotted 1px;
        border-radius: 5px;
        padding: 5px;
        word-break: break-word;
        font-size: 12px;

        .content-key {
          color: #909399;
          font-weight: 600;
        }

        .content-value {
          margin-top: 10px;

          .content-value1 {
            color: #909399;
            margin-bottom: 10px;
          }

          .content-value2 {
            color: #606266;
            font-weight: 600;

            .line2 {
              height: 32px;
              overflow: hidden;
            }

            .line3 {
              height: 50px;
              overflow: hidden;
            }

            .smallsize {
              font-size: 12px;
              -webkit-transform-origin-x: 0;
              -webkit-transform: scale(0.9);
            }

            .smallsize-font {
              font-size: 10.8px;
            }
          }

          .content-value3 {
            color: #606266;

            /deep/ .el-image__error {
              font-size: 12px;
            }

            .el-image {
              max-height: 200px;
              vertical-align: middle;
            }

            .brand-img {
              width: 100%;
              height: 0;
              padding: 0;
              padding-bottom: 60%;

              /deep/ img {
                position: absolute;
              }

              /deep/ .el-image__error {
                position: absolute;
              }
            }

            .product-img {
              width: 100%;
            }

            .color-img {
              width: 100%;
            }

            .progress-bar {
              padding-right: 0;
              margin-right: 0;
              display: inline-block;
              vertical-align: middle;
              width: 100%;
              box-sizing: border-box;

              .progress-bar-outer {
                height: 0;
                padding: 0;
                padding-bottom: 60%;
                // height: 28px;
                background-color: #c0c4cc;
                overflow: hidden;
                position: relative;
                vertical-align: middle;

                .progress-bar-inner {
                  position: absolute;
                  left: 0;
                  top: 0;
                  height: 100%;
                  background-color: #409eff;
                  // text-align: right;
                  line-height: 1;
                  white-space: nowrap;
                  transition: width 0.6s ease;

                  .progress-bar-text {
                    display: inline-block;
                    vertical-align: middle;
                    color: #fff;
                    font-size: 12px;
                    margin: 0 5px;
                  }
                }

                .progress-bar-inner:after {
                  display: inline-block;
                  content: "";
                  height: 100%;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }

  .board-container {
    margin-bottom: -10px;

    .el-col {
      margin-bottom: 10px;
    }
  }
}
</style>